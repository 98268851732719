import request from '@/api/request.js'; //请求对象

//获取分类列表
export function read(data) {
  return request({
    url: '/admin_category_v2/read',
    method: 'post',
    data
  })
}

//创建分类
export function create(data) {
  return request({
    url: '/admin_category_v2/create',
    method: 'post',
    data
  })
}

//修改分类
export function update(data) {
  return request({
    url: '/admin_category_v2/update',
    method: 'post',
    data
  })
}

//删除分类
export function remove(data) {
  return request({
    url: '/admin_category_v2/remove',
    method: 'post',
    data
  })
}
//删除分类下的音效
export function removeMenuSound(data) {
  return request({
    url: '/admin_category_v2/removeMenuSound',
    method: 'post',
    data
  })
}

//获取一个分类的内容
export function ReadOneMenu(data) {
  return request({
    url: '/admin_category_v2/ReadOneMenu',
    method: 'post',
    data
  })
}

//修改分类音效排序
export function updateOneMenuSoundsSort(data) {
  return request({
    url: '/admin_category_v2/updateOneMenuSoundsSort',
    method:'POST',
    data
  })
}
//修改分类排序
export function updateOneMenusSort(data) {
  return request({
    url: '/admin_category_v2/updateOneMenusSort',
    method:'POST',
    data
  })
}

