<template>
  <div class="app-container">
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      @cell-click="cellClick"
      :row-class-name="bgmRowClassCallback"
    >
      <el-table-column align="center" label="ID" width="50">
        <template slot-scope="scope">
          {{ scope.$index+1 }}
        </template>
      </el-table-column>
      <el-table-column label="分类名" width="300">
        <template slot-scope="scope">
          <template v-if="scope.row.isEditName">
            <el-row :gutter="5">
              <el-col :span="16">
                <el-input v-model="scope.row.name" @blur="isCanDrag = true" 
                maxlength="10" minlength="2" :autofocus="true" size="mini" placeholder="请输入内容"></el-input>
              </el-col>
              <el-col :span="4">
                <el-button icon="el-icon-close" circle size="mini" @click="scope.row.isEditName=false"></el-button>
              </el-col>
              <el-col :span="4">
                <el-button type="success" icon="el-icon-check" circle size="mini" @click="update(scope.row)"></el-button>
              </el-col>
            </el-row>
          </template>
          <template v-else>
            {{ scope.row.name }}
          </template>
        </template>
      </el-table-column>

      <el-table-column label="状态" width="70">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.status" @change="update(scope.row)" :active-value="1" :inactive-value="0"></el-switch>
        </template>
      </el-table-column>

      <el-table-column label="类型" width="70">
        <template slot-scope="scope">
          {{ showTypeName(scope.row.type) }}
        </template>
      </el-table-column>

      <el-table-column label="是否免费" width="100">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.is_free" @change="update(scope.row)" :active-value="1" :inactive-value="0"></el-switch>
        </template>
      </el-table-column>
      
      <el-table-column label="包含音效" width="95">
        <template slot-scope="scope">
          {{scope.row.sounds_num}}个
        </template>
      </el-table-column>
      <el-table-column label="创建时间" width="100" align="center">
        <template slot-scope="scope">
          {{scope.row.create_time_str}}
        </template>
      </el-table-column>

      <el-table-column label="编辑" align="right">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" @click="edit(scope.row)">编辑音效</el-button>
          <el-button type="danger" icon="el-icon-delete" @click="deleteMenu(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page_num"
      :page-sizes="[5,10,15,20]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      style="margin-top:10px"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
import { read,remove, update, updateOneMenusSort } from '@/api/categoryv2'

export default {
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  },
  data() {
    return {
      list: null,
      listLoading: true,
      page_num:1,
      page_size:5,
      total:0,
      search_op:{},
      audio: {
          name: '',
          artist: '蜗牛音效',
          url: '',
          cover: '', // prettier-ignore
          // lrc: 'https://cdn.moefe.org/music/lrc/thing.lrc',
      },
      currDragOverSoundIndex:null, //当前拖拽经过的音效按钮
      currDragOverSoundDirection:null, //当前拖拽经过的音效放置方向  top bottom
      currDragSoundIndex:null, //当前拖拽的音效按钮
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    showTypeName(type){
      switch(type){
        case 'sound':
          return '音效';
          break;
        case 'music':
          return '背景音乐';
          break;
        case 'all':
          return '通用';
          break;
      }
    },
    cellClick(row, column, cell, event){
      if(row.isEditName || column.label != "分类名") return;
      console.log("修改分类名12",row, column, cell, event)
      if(column.label == "分类名"){
        console.log("修改分类名")
        this.$set(row,'isEditName',true)
      }
    },
    play(row){
      this.audio.url = row.full_path;
      this.audio.name = row.name;
      this.$refs.aplayer.play();
    },
    update(row){
      let op = {
        name:row.name,
        category_v2_id:row.id,
        status:row.status,
        is_free:row.is_free,
        type:row.type
      }
      update(op).then(response => {
        if(response.data.code = 1){
          this.$message({
            type:"success",
            message:'修改成功'
          })
          this.fetchData(true)
        }
      })
    },
    edit(row){
      this.$router.push({path:'/sounds_v2/edit_menu',query:{menu_id:row.id}})
    },
    deleteMenu(row){
      if(row.sounds_num>0){
        this.$message({
          type:"warning",
          message:'无法删除，该分类下包含音效'
        })
        return;
      }
      this.$confirm('确认删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        remove({category_v2_id:row.id}).then(response => {
          if(response.data.code = 1){
            this.$message({
              type:"success",
              message:'删除成功'
            })
            this.fetchData(true)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    },
    handleSizeChange(val) {
      this.page_size = val
      this.fetchData(false)
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.page_num = val
      this.fetchData(false)
      console.log(`当前页: ${val}`);
    },
    fetchData(isSearch=false) {
      if(isSearch){
        this.page_num = 1;
      }
      const op = {
        page_num:this.page_num,
        page_size:this.page_size
      }
      Object.assign(op,this.search_op)
      this.listLoading = true
      read(op).then(response => {
        this.list = response.data.data.list
        this.total = response.data.data.count
        this.listLoading = false
        this.initDragDropEvent()
      })
    },
    bgmRowClassCallback(arg){
      const {row,rowIndex} = arg
      // console.log(row,index)
      let classStr = 'bgmRow ';
      if(rowIndex == this.currDragSoundIndex) classStr+= 'drag-curr ';
      // if(rowIndex == this.currDragOverSoundIndex) classStr+= 'drag-over ';
      if(this.currDragSoundIndex !=null && this.currDragOverSoundIndex==rowIndex && this.currDragOverSoundDirection=='top') classStr+= 'drag-over-top ';
      if(this.currDragSoundIndex !=null && this.currDragOverSoundIndex==rowIndex && this.currDragOverSoundDirection=='bottom') classStr+= 'drag-over-bottom ';
      return classStr;
    },
    // cancleDrag(){
    //   window.removeEventListener();
    // },
    initDragDropEvent(){
      this.$nextTick(()=>{
        //拖拽排序
        let ref_bgms = window.document.getElementsByClassName('bgmRow')
        if(ref_bgms){
          ref_bgms.forEach((d,index)=>{
            console.log("设置了可以拖动")
            let isAddListener = d.getAttribute('data-i')
            if(isAddListener) return;

            d.setAttribute('draggable',true)
            d.setAttribute('data-i',index)
            //开始拖动音效按钮
            d.addEventListener('dragstart',(e)=>{
              const drag_i = e.target.getAttribute('data-i');
              this.currDragSoundIndex = drag_i
              e.dataTransfer.setData("data_i_drag",drag_i);
            })

            d.addEventListener('dragend',(e)=>{
              console.log("拖动完成")
              this.currDragOverSoundIndex = null;
              this.currDragSoundIndex = null;
              this.currDragOverSoundDirection = null
            })
            d.addEventListener("drop",(e)=>{
              e.preventDefault();

              const data_i_drag = parseInt( e.dataTransfer.getData('data_i_drag') )
              if(data_i_drag){
                // console.log('获取到的data_i_drag',data_i_drag)
              }
              const data_i_drop = parseInt( d.getAttribute('data-i') )
              // console.log("当前音效块data_i_drop",data_i_drop)

              //交换位置
              this.soundLayout(data_i_drag,data_i_drop);

              this.currDragOverSoundIndex = null;
              this.currDragSoundIndex = null;
              this.currDragOverSoundDirection = null
            });
            d.addEventListener("dragover", (e) => {
              e.stopPropagation();
              e.preventDefault();
              e.dataTransfer.setData("data_i_drop",d.getAttribute('data-i'));
              this.currDragOverSoundIndex = d.getAttribute('data-i');
              let c = e.offsetY
              if(c>d.clientHeight/2){
                // console.log("下边")
                this.currDragOverSoundDirection = 'bottom'
              }else{
                this.currDragOverSoundDirection = 'top'
                // console.log("上边")
              }

            })
            d.addEventListener("dragleave", (e) => {
              e.preventDefault();
              this.currDragOverSoundIndex = null;
              // e.dataTransfer.dropEffect = "move"
            })
          })
        }

      })
    },
    //菜单移动位置
    soundLayout(drag_i=null,drop_i=null){
      if(drag_i ==null || drop_i==null) return;
      if(drag_i == drop_i) return;
      let musics = this.list;
      let drag = musics.splice(drag_i,1); //被拖动的 return array

      let newIndex = null
      if(this.currDragOverSoundDirection == 'top'){
        //如果在他前面抽出了一个元素，实际drop_i会变小1位
        if(drag_i<drop_i){
          musics.splice(drop_i-1,0,...drag)
          newIndex = drop_i-1
        }else{
          //后面抽出不变
          newIndex = drop_i
          musics.splice(drop_i,0,...drag)
        }
      }else{
        if(drag_i<drop_i){
          newIndex = drop_i
          musics.splice(drop_i,0,...drag)
        }else{
          newIndex = drop_i+1
          musics.splice(drop_i+1,0,...drag)
        }
      }
      console.log("新列表",this.list)
      let package_menus_id_sort = this.list.map(v=>{return parseInt(v.id)})
      let op = {
        menu_id:this.$route.query.menu_id, 
        'package_menus_id_sort':package_menus_id_sort.join(',')
      }
      updateOneMenusSort(op).then(res=>{
          this.$message({
              message: '排序成功!',
              type: 'success'
          })
      }).catch(err=>{
        this.$message({
            message: '排序失败!',
            type: 'error'
        })
      })
      // this.musicList = musics
    },
  }
}
</script>
<style lang="scss">
.app-container{
  width: 90%;
  margin: auto;
}
  .drag-over{
    td{
      // border-top: 1px solid red !important;
      // border-bottom: 1px solid red !important;
    }
  }
  .drag-over-top{
    td{
      border-top: 2px solid red !important;
    }
  }
  .drag-over-bottom{
    td{
      border-bottom: 2px solid red !important;
    }
  }
  .drag-curr{
    // cursor:move !important;
    opacity: .3;
  }
</style>